$(document).ready(function() {
	$(".image").lightBox();
	$( "#query" ).autocomplete({
		source: window.JS_TAGS
	});
});

window.ToOpen = function(addr, wid) {
	window.open(addr, 'new', 'width=' + wid + ',height=900,toolbar=1,scrollbars=1');
}
